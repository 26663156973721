import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "../containers/session/routes";
import landinPageRoutes from "../containers/landingPage/routes";
import newReportRoutes from "../containers/new-report/routes";
import connectPageRoutes from "../containers/connectPage/routes";
import helpPageRoutes from "../containers/Help/routes";
import newDashboardRoutes from "../containers/new-dashboard/routes";
import offlineMode from "../containers/offline-mode/routes";
import purchaseLisanceRoute from "../containers/purchase/routes";
import dispositionRoutes from "app/containers/Disposition/routes";
import incidentRoutes from "app/containers/IncidentViewer/routes";
import settingsPage from "app/containers/Settings/routes";
import PartnerViewHomePageRoutes from "app/containers/PartnerView/HomePage/routes";
import assertionAiRoutes from "app/containers/AssertionAI/routes";
import workflowMangerRoutes from "app/containers/TriggeredActivities/routes";
import blockZoneRoutes from "app/containers/BlockZone/routes";
import remoteWorkerRoutes from "app/containers/RemoteWorker/routes";
import dashboardRoutes from "app/containers/Dashboard/routes";
import callsScreenedRoutes from "app/containers/CallsScreened/routes";
import OutageTimeHistoryRoutes from "app/containers/OutageTimeHistory/routes";
import workflowAdvancedSettingsRoutes from "app/containers/Settings/partitions/WorkflowManager/routes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/" />,
  },
  {
    path: "/connectPage",
    exact: true,
    component: () => <Redirect to="/SignIn" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...workflowAdvancedSettingsRoutes,
  ...OutageTimeHistoryRoutes,
  ...callsScreenedRoutes,
  ...dashboardRoutes,
  ...remoteWorkerRoutes,
  ...blockZoneRoutes,
  ...assertionAiRoutes,
  ...workflowMangerRoutes,
  ...PartnerViewHomePageRoutes,
  ...purchaseLisanceRoute,
  ...settingsPage,
  ...offlineMode,
  ...newDashboardRoutes,
  ...incidentRoutes,
  ...newReportRoutes,
  ...connectPageRoutes,
  ...dispositionRoutes,
  ...helpPageRoutes,
  ...sessionRoutes,
  ...landinPageRoutes,
  ...errorRoute,
  ...redirectRoute,
];

export default routes;
