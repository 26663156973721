import React, { createContext, useContext, useState } from "react";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { dateRange } from "app/constants/dateRange";

const DateContext = createContext({
  startDate: startOfDay(addDays(new Date(), -dateRange)),
  endDate: endOfDay(new Date()),
});

function DateProvider({ children }) {
  const [globalDate, setGlobalDate] = useState({
    startDate: startOfDay(addDays(new Date(), -dateRange)),
    endDate: endOfDay(new Date()),
  });

  return (
    <DateContext.Provider value={{ globalDate, setGlobalDate }}>
      {children}
    </DateContext.Provider>
  );
}

const useDate = () => useContext(DateContext);

export { useDate, DateProvider };
