import React, { useEffect } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import useSignIn from "./hooks";
import Modal from "../partitions/modal";
import cssclasses from "../session.module.css";
import clsx from "clsx";
import Flex from "app/components/Flex/Flex";
import useMessage from "app/hooks/useMessage";
import { useQuery } from "react-query";
import axios from "axios";
import aws4Interceptor from "aws4-axios";
import api from "app/apis/api";
import { authUser, authUserCredentials } from "app/constants";
import { setItem } from "app/services/localStorageService";
import {
  baseURL,
  identity_provider,
  aws_region,
  custom_cognito_client_id,
} from "config";

const SignIn = function (props) {
  const {
    handleChange,
    handleFormSubmit,
    email,
    password,
    withOtp,
    loading,
    resendOtp,
    isResendingOtp,
    setWithOtp,
    buttons,
    isOpen,
    handleClose,
    handleSubmit,
    value,
    handleChangeOptionChange,
  } = useSignIn(props);
  const data = useLocation();
  const user =
    new URLSearchParams(data.search).get("user") ||
    window.location.host.includes("gehc");
  const code = new URLSearchParams(data.search).get("code");

  const history = useHistory();

  const message = useMessage();

  const redirect_uri = window.location.origin + window.location.pathname;

  const fetchJWTTokens = useQuery(
    ["fetchJWTTokensGEHC", code],
    () => {
      return axios.post(
        `${baseURL}/users/getSessionTokenFromCode`,
        {
          code,
          redirect_uri,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      enabled: !!code,
      onError: (error) => {
        console.log(error.response);
        message("error", "Failed fetch tokens, Invalid code");
      },
    }
  );

  const fetchAwsAuthDetails = useQuery(
    ["fetchAwsAuthDetailsGEHC"],
    () => {
      return axios.post(
        `${baseURL}/users/generateSessionCredentials`,
        {
          idToken: fetchJWTTokens.data.data.data.id_token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
    },
    {
      enabled: !!fetchJWTTokens.data?.data?.data?.id_token,
    }
  );

  useEffect(() => {
    if (fetchAwsAuthDetails.isSuccess && fetchAwsAuthDetails.data) {
      const awsAuthDetails = fetchAwsAuthDetails.data.data.data.credentials;
      setItem(authUser, {
        accessToken: fetchJWTTokens.data.data.data.access_token,
        idToken: fetchJWTTokens.data?.data?.data?.id_token,
        refreshToken: fetchJWTTokens.data?.data?.data?.refresh_token,
      });
      setItem(authUserCredentials, {
        accessKeyId: awsAuthDetails.accessKeyId,
        secretAccessKey: awsAuthDetails.secretAccessKey,
        sessionToken: awsAuthDetails.sessionToken,
      });
      setItem("cognitoClientId", custom_cognito_client_id);
      const interceptor = aws4Interceptor(
        {
          region: aws_region,
          service: "execute-api",
        },
        {
          accessKeyId: awsAuthDetails.accessKeyId,
          secretAccessKey: awsAuthDetails.secretAccessKey,
          sessionToken: awsAuthDetails.sessionToken,
        }
      );
      api.interceptors.request.use(interceptor);
      history.push("/summary-report");
    }
  }, [fetchAwsAuthDetails.isSuccess]);

  const default_content = (
    <>
      {withOtp && (
        <div className="px-24">
          <IconButton
            onClick={() => {
              setWithOtp(false);
            }}
          >
            <i className="fa fa-arrow-circle-left mg-t-20 font-size-22" />
          </IconButton>
        </div>
      )}
      <div
        className={
          withOtp
            ? "px-36 pb-40 flex flex-center flex-middle h-100"
            : "p-36 flex flex-center flex-middle h-100"
        }
      >
        <ValidatorForm onSubmit={handleFormSubmit}>
          <TextValidator
            className={clsx("mb-24 w-100", cssclasses.input_width)}
            variant="outlined"
            label="Email"
            onChange={handleChange}
            type="email"
            name="email"
            value={email}
            placeholder="abc@example.com"
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            disabled={withOtp}
          />
          {withOtp && (
            <TextValidator
              className={clsx("mb-16 w-100", cssclasses.input_width)}
              label="Verification Code"
              variant="outlined"
              onChange={handleChange}
              name="password"
              placeholder="Enter Verification Code"
              type="password"
              value={password}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
          )}
          <div className="flex flex-middle mb-8">
            <div className={cssclasses.wrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || isResendingOtp}
              >
                {loading && !isResendingOtp && (
                  <CircularProgress size={24} className="ml-5 mr-8" />
                )}
                {withOtp ? "Verify Code" : "Sign In"}
              </Button>
            </div>
            {withOtp ? (
              <div className="ml-16 mr-8">
                <Button
                  className="capitalize"
                  onClick={() => resendOtp()}
                  disabled={loading}
                >
                  {isResendingOtp && (
                    <CircularProgress size={24} className="ml-5 mr-8" />
                  )}
                  Resend Code
                </Button>
              </div>
            ) : (
              <div>
                <span className="ml-16 mr-8">or</span>
                <Button
                  className="capitalize"
                  onClick={() => props.history.push("/session/signup")}
                  disabled={loading}
                >
                  Sign up
                </Button>
              </div>
            )}
          </div>
          {withOtp && (
            <Flex direction="row-reverse">
              <Button
                className="mb-0 ml-2"
                onClick={() => {
                  handleClose();
                }}
              >
                Having difficulty to sign In ?
              </Button>
            </Flex>
          )}

          <Modal
            buttons={buttons}
            isOpen={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            value={value}
            handleChangeOptionChange={handleChangeOptionChange}
            title="Having difficulty to Sign In"
          />
        </ValidatorForm>
      </div>
    </>
  );
  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                <img src="/assets/images/assertion.png" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              {user || code ? (
                <div className="p-24 m-24">
                  {fetchAwsAuthDetails.isLoading || fetchJWTTokens.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Flex direction="col">
                      <Typography variant="h6">
                        GE Healthcare SecureVoice
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        className=" m-24"
                        onClick={() =>
                          (window.location.href = `https://sbch-prod-auth-upload.auth.us-east-1.amazoncognito.com/authorize?identity_provider=${identity_provider}&redirect_uri=${redirect_uri}&response_type=CODE&client_id=${custom_cognito_client_id}&scope=email%20openid%20profile`)
                        }
                      >
                        SSO login
                      </Button>
                    </Flex>
                  )}
                </div>
              ) : (
                default_content
              )}
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
